.new-news {
  min-height: 100vh;
  margin: 100px 30px 50px 30px;
  padding: 15px;
  background: white;
}

.sun-editor .se-tooltip {
  left: 0;
  top: 0;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-footer>div>label {
  margin-top: -10px;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-footer {
  padding: 10px 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}

.profile-container ul {
  display: flex;
  grid-gap: 30px;
}

.profile-container ul.se-list-basic{
  display: grid;
  grid-template-columns: none;
}

.profile-container ul li button {
  right: 0px;
  top: 0px;
}

.sun-editor .se-list-layer .se-list-inner {
  overflow: auto;
}

.sun-editor .se-wrapper .se-wrapper-inner {
  min-height: 30vh;
}

.se-wrapper-inner.se-wrapper-wysiwyg.sun-editor-editable ul {
  display: block;
}