/* @import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500;700&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  font: 400 14px Poppins, sans-serif;
  font-family: "Poppins", sans-serif !important;
  background: #efeee8 !important;
  -webket-font-smoothing: antialiased;
}

input,
button,
textarea {
  font: 400 18px Roboto, sans-serif;
}

button {
  cursor: pointer;
}

form input:not([type="file"]) {
  width: 100%;
  height: 60px;
  color: #333;
  border: 1px solid #dcdce6;
  border-radius: 8px;
  padding: 0px 24px;
}

form textarea {
  width: 100%;
  min-height: 140px;
  height: 60px;
  color: #333;
  border: 1px solid #dcdce6;
  border-radius: 8px;
  padding: 16px 24px;
  line-height: 24px;
  resize: vertical;
}

.button {
  width: 100%;
  height: 60px;
  background-color: #a62639;
  border: 0px;
  border-radius: 8px;
  color: #fff;
  font-weight: 700;
  margin-top: 16px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  line-height: 60px;
  transition: filter 0.2s;
}

.button:hover {
  filter: brightness(90%);
}

.back-link {
  display: flex;
  align-items: center;
  margin-top: 40px;
  color: #41414d;
  font-size: 18px;
  text-decoration: none;
  font-weight: 500;
  transition: opacity 0.2s;
}

.back-link svg {
  margin-right: 8px;
}

.back-link:hover {
  opacity: 0.8;
}

/* geral */
.profile-container {
  width: 100%;
}

header {
  z-index: 10;
}

.profile-container header {
  position: fixed;
  top: 0;
}

.profile-container div.body {
  overflow: hidden;
}

.profile-container header {
  background-color: #560e0c;
  padding: 5px;
  box-shadow: 0 0 15px 0px #0000007a;
  width: 100%;
}

.profile-container header.fixed {
  display: flex;
  justify-content: space-between;
}

.profile-container header:not(.fixed) {
  display: block;
  justify-content: center;
}

.profile-container header span {
  display: block;
  font-size: 15px;
  margin-left: 24px;
  color: #fff;
}

.profile-container header.fixed img {
  height: 45px;
  margin-left: 10px;
}

header:not(.fixed) #logo {
  margin-left: calc(50% - 55px);
  width: 110px;
  padding: 10px;
}

header:not(.fixed) #slogan {
  margin-left: calc(50% - 143px);
  width: 286px;
  padding: 10px;
}

.profile-container header button {
  height: 60px;
  width: 60px;
  border-radius: 4px;
  background: transparent;
  margin-left: 16px;
  transition: border-color 0.2s;
}

.profile-container h1 {
  margin-top: 80px;
  margin-bottom: 24px;
}

.profile-container ul {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  list-style: none;
}

.profile-container ul li button {
  position: absolute;
  right: 24px;
  top: 24px;
  border: 0;
}

.profile-container ul li button:hover {
  opacity: 0.8;
}

.profile-container ul li strong {
  display: block;
  margin-bottom: 16px;
  color: #41414d;
}

.profile-container ul li p + strong {
  margin-top: 32px;
}

.profile-container ul li p {
  color: #737380;
  line-height: 21px;
  font-size: 16px;
}

header:not(.fixed) .logo-section {
  width: 100vw;
  display: block;
}

header.fixed .logo-section {
  width: 220px;
  display: flex;
  padding: 5px;
}

.navs-section {
  display: flex;
  padding-right: 20px;
}
.navs-section::before {
  background-color: #0000004f;
  width: 100vw;
  height: 100vh;
  position: fixed;
  content: "";
  left: 0;
  top: 0;
  opacity: 0;
  display: none;
  transition: .6s;
}

header.fixed .navs-section {
  width: auto;
  justify-content: flex-end;
}

header:not(.fixed) .navs-section {
  justify-content: center;
}

.btn-list {
  outline: 0;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  max-width: 260px;
  border: 0;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px 10px;
  color: #ffffff !important;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1.2px;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
}

.btn-list:hover {
  text-decoration: none !important;
}

.btn-list span::after {
  display: block;
  background-color: #aa9166;
  content: "";
  width: 100%;
  height: 2px;
  visibility: hidden;
  transform: scaleX(0);
  transform-origin: bottom left;
  transition: all 0.25s ease-out;
}

.btn-list:hover span {
  color: #aa9166 !important;
}

.btn-list:hover span::after {
  visibility: visible;
  -moz-transform: scaleX(1);
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.btn-list span {
  transition: 0.2s;
}

.dropdown-active {
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: left;
  opacity: 1 !important;
  visibility: visible !important;
  transform: translateX(15px);
}

.btn .material-icons {
  border-radius: 100%;
  -webkit-animation: ripple 0.6s linear infinite;
  animation: ripple 0.6s linear infinite;
}
.btn-list .dropdown {
  position: absolute;
  background: #ffffff;
  min-width: 150px;
  margin-left: -5px;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: left;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  display: block;
}

header:not(.fixed) .btn-list .dropdown {
  top: 290px;
}

header.fixed .btn-list .dropdown {
  top: 60px;
}

.btn-list .dropdown li {
  z-index: 1;
  position: relative;
  background: #ffffff;
  padding: 0 20px;
  color: #666;
}
.btn-list .dropdown li.active {
  color: #5380f7;
}
.btn-list .dropdown li:first-child {
  border-radius: 4px 4px 0 0;
}
.btn-list .dropdown li:last-child {
  border-radius: 0 0 4px 4px;
}
.btn-list .dropdown li:last-child a {
  border-bottom: 0;
}
.btn-list .dropdown a {
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 16px 0;
  color: inherit;
  font-size: 14px;
  text-decoration: none;
}

iframe {
  text-align: center;
}

.video-frame {
  width: 100vw;
  height: calc(100vh - 84px);
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Poppins, sans-serif;
  line-height: 1.4074;
  color: #1f1f25;
}

footer {
  position: relative;
  background: #4e0d0b;
  padding: 30px;
  padding-bottom: 10px;
  min-height: 370px;
  display: flex;
}

footer img {
  height: 50px;
}

footer span.logo-text {
  position: absolute;
  top: 4px;
  right: calc(50% - 77px);
  left: calc(50% - 77px);
  color: white;
  font-weight: bold;
}

footer span.line-left {
  position: absolute;
  top: 34px;
  width: calc(48% - 77px);
  height: 1px;
  left: 1%;
  background: white;
}

footer span.line-right {
  position: absolute;
  top: 34px;
  width: calc(48% - 77px);
  height: 1px;
  background: white;
  right: 1%;
}

footer .contacts span {
  display: block;
}

footer .contacts, footer .contacts a {

  color: #aa9166;
}

footer {
  margin-left: 15px;
}
footer .col-12.col-md-4{
  margin-top: 30px;
  padding-top: 5%;
}

footer .contacts span:hover, footer .contacts a:hover {
  color: #fff ;
  cursor: pointer;
}

footer .contacts span, footer .contacts a {
  transition: 0.2s;
  font-weight: 400 !important;
  justify-content: center;
  display: flex;
}

footer .contacts span + span {
  margin-top: 5px;
}

footer .social-media a{
  margin-top: 20px;
  color: #aa9166;
}

footer .social-media{
  padding-top: 20px;
  margin-left: -5px;
  justify-content: center;
  display: flex;
}

footer .social-media svg {
  transition: 0.2s;
}

footer .social-media a + a {
  margin-left: 15px;
}

footer .social-media svg:hover {
  color: #fff ;
  cursor: pointer;
}

footer .address {
  padding-top: 30px;
  width: 100%;
  height: 100%;
}

.bold {
  font-weight: bold;
}

.mt-20 {
  margin-top: 20px !important;
}

.contacts svg {
  margin-right: 10px;
}

.scroll-down {
  color: white !important;
  text-decoration: none;
  display: flex !important;
  justify-content: center;
}

a:hover {
  text-decoration: none !important;
}

a:hover .scroll-down {
  color: #f8cf47 !important;
}

.mb--30 {
  margin-bottom: 30px;
}

.ham {
  display: none;
}

#mapa {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

@media (min-width: 768px) and (max-width: 920px) {
  .btn-list {
    padding: 15px 5px !important;
  }

  .profile-container header span {
    margin-left: 5px !important;
  }
}

@media (max-width: 768px) {

  .profile-container header.fixed img {
    height: 32px !important;
  }

  .profile-container header.fixed {
    height: 60px;
  }

  .ham {
    width: 55px;
    display: flex !important;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: transform 400ms;
    -webkit-user-select: none;
    user-select: none;
    position: absolute;
    top: 5px;
    right: 0;
  }
  .hamRotate.active {
    transform: rotate(45deg);
  }
  .hamRotate180.active {
    transform: rotate(180deg);
  }
  .line {
    fill: none;
    transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
    stroke: #fff;
    stroke-width: 5.5;
    stroke-linecap: round;
  }

  .ham8 .top {
    stroke-dasharray: 40 160;
  }
  .ham8 .middle {
    stroke-dasharray: 40 142;
    transform-origin: 50%;
    transition: transform 400ms;
  }
  .ham8 .bottom {
    stroke-dasharray: 40 85;
    transform-origin: 50%;
    transition: transform 400ms, stroke-dashoffset 400ms;
  }
  .ham8.active .top {
    stroke-dashoffset: -64px;
  }
  .ham8.active .middle {
    transform: rotate(90deg);
  }
  .ham8.active .bottom {
    stroke-dashoffset: -64px;
  }

  .navs-section.active {
    left: 0;
    background-color: #560e0c;
    border-radius: 0px 0px 10px 0px;
    box-shadow: 0px 20px 20px #fdfdfd0d;
  }
  .navs-section {
    position: absolute;
    top: 0;
    left: -330px;
    display: grid;
    transition: .5s;
  }

  .navs-section.active::before {
    opacity: 1;
    display: inline;
  }

  header .btn-list .dropdown {
    top: 320px !important;
  }

  .parallax-slogan img{
    width: 70vw !important;
  }
}

#publications.news-area {
  min-height: 50vh;
}

.awssld__content {
  background-color: #000 !important;
}

.awssld__bullets {
  display: none !important;
}